import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import RegisterSection from "../components/chat-channel/ChannelHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../components/chat-channel/LeftImageWithContent";
import { ArrowRightICon, MobNextArrowIcon } from "../components/common/Icons";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import Cards from "../components/chat-channel/Cards";
import useInterval from "../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";
import { useMedia } from "use-media";

const servicenow = require("../assets/images/icons-web/servicenow.svg");
const freshservice = require("../assets/images/icons-web/freshservice.svg");
const jira = require("../assets/images/icons-web/jira.svg");
const solarwinds = require("../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../assets/images/icons-web/service_desk.svg");
const jumpcloud = require("../assets/images/icons-web/jumpcloud.svg");
const ad = require("../assets/images/icons-web/zure.svg");
const office365 = require("../assets/images/icons-web/ms_office.svg");
const okta = require("../assets/images/icons-web/okta.svg");
const onelogin = require("../assets/images/icons-web/onelogin.svg");
const bamboo = require("../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../assets/images/icons-web/orangehrm.svg");
const zoho_people = require("../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../assets/images/icons-web/gusto.svg");
const sap = require("../assets/images/icons-web/sap_successfactors.svg");
const intune = require("../assets/images/icons-web/ms_intune.svg");
const mobileiron = require("../assets/images/icons-web/mobile_iron.svg");
const hexnode = require("../assets/images/icons-web/hexnode.svg");
const citrix = require("../assets/images/icons-web/citrix.svg");

const mobile = require("../assets/landing_servicenow/ITC_mob_logos_IT_chatbot.png");

const topImage = require("../assets/landing_servicenow/ITC_it_chatbot_anim_header_IT_chatbot.gif");

const mock_video = require("../assets/landing_servicenow/ITC_video_overlay.png");

const section_two = require("../assets/landing_servicenow/ITC_01_it_chatbot_IT_chatbot.png");
const section_four = require("../assets/landing_servicenow/ITC_03_marketplace_IT_chatbot.png");
const section_five = require("../assets/landing_servicenow/ITC_04_omnichannel_IT_chatbot.png");
const section_six = require("../assets/landing_servicenow/ITC_05_agent_handover_IT_chatbot.png");

const icon1 = require("../assets/landing_servicenow/icon_1.png");
const icon2 = require("../assets/landing_servicenow/icon_2.png");
const icon3 = require("../assets/landing_servicenow/icon_3.png");
const icon4 = require("../assets/landing_servicenow/icon_4.png");
const icon5 = require("../assets/landing_servicenow/icon_5.png");

const noCoding = require("../assets/images/landing_page/bottom_icon_1.png");
const supportAutomation = require("../assets/images/landing_page/bottom_icon_2.png");
const boostIT = require("../assets/images/landing_page/bottom_icon_3.png");

const access_management = require("../assets/landing_servicenow/ITC_02_access_menagement_IT_chatbot.png");
const asset_requests = require("../assets/landing_servicenow/ITC_02_asset_request_IT_chatbot.png");
const password_resets = require("../assets/landing_servicenow/ITC_02_reset_password_IT_chatbot.png");
const unlock_account = require("../assets/landing_servicenow/ITC_02_unlock_account_IT_chatbot.png");
const user_provisioning = require("../assets/landing_servicenow/ITC_02_user_provisioning_IT_chatbot.png");
const offboarding = require("../assets/landing_servicenow/ITC_02_offboarding_IT_chatbot.png");
const onboarding = require("../assets/landing_servicenow/ITC_02_onboarding_IT_chatbot.png");

const cardData = [
  {
    header: "Encryption",
    content:
      "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit.",
    image: noCoding,
  },
  {
    header: "Access Controls",
    content:
      "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy.",
    image: supportAutomation,
  },
  {
    header: "Connect On-prem Apps",
    content: `Connect IT helpdesk chatbot with your on-prem apps using our <span class="secure_nolink"><a href='javascript:void(0);'>secure connector</a></span>  so you can create workflows to automate tasks from IT helpdesk chatbot without a hitch, be it on-prem or cloud.`,
    image: boostIT,
  },
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
const headerMenu = [
  {
    menu: "Customers",
    href: "#customer",
    id: "customer",
    offset: null,
  },
  {
    menu: "IT Helpdesk Chatbot",
    href: "#chatbot",
    id: "chatbot",
    offset: 100,
  },
  {
    menu: "IT Workflows",
    href: "#workflow",
    id: "workflow",
    offset: 450,
  },
  {
    menu: "Marketplace",
    href: "#marketplace",
    id: "marketplace",
    offset: 150,
  },
  {
    menu: "Slack & MS Teams",
    href: "#slack",
    id: "slack",
    offset: 150,
  },
  {
    menu: "App Integrations",
    href: "#integrations",
    id: "integrations",
    offset: 150,
  },
];

const OpenMenu = () => {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const [showMenu, setShowMenu] = React.useState(false);
  const onClick = () => setShowMenu(!showMenu);
  return (
    <>
      <a
        className={
          !showMenu
            ? "navbar-brand js-scroll-trigger"
            : "navbar-brand js-scroll-trigger icon_open"
        }
        href="javascript:void(0);"
      >
        <img 
          style={{ cursor: "default" }}
          src={require("../assets/images/workativ-logo.png")}
          alt="Workativ"
        />
      </a>
      <span>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className={
              !showMenu
                ? "navbar-toggler-icon"
                : "navbar-toggler-icon icon_open"
            }
            onClick={onClick}
          >
            {!showMenu ? (
              <svg viewBox="0 -53 384 384">
                <path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"></path>
              </svg>
            ) : (
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path>
              </svg>
            )}
          </span>
        </button>
      </span>
      {showMenu ? (
        <div id="results" className="search-results">
          <div className="navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {headerMenu.map((menu) => (
                <li className="nav-item" onClick={onClick}>
                  <AnchorLink
                    offset={() => 160}
                    href={menu.href}
                    className={
                      isActive === menu.menu
                        ? "url_manipulation nav-link js-scroll-trigger active"
                        : "url_manipulation nav-link js-scroll-trigger "
                    }
                    onClick={() => {
                      setIsActive(menu.menu);
                    }}
                  >
                    {" "}
                    {menu.menu}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Menu = () => (
  <div id="results" className="search-results">
    <div className="navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#chatbot"
          >
            HR Chatbot
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#workflow"
          >
            HR Workflows
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#marketplace"
          >
            Marketplace
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#slack"
          >
            Slack & MS Teams
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#integrations"
          >
            App Integrations
          </AnchorLink>
        </li>
      </ul>
    </div>
  </div>
);

export default function FeatureHomePage() {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slides = [
    {
      image: unlock_account,
      title: "Unlock Account",
      tabFor: "vertical-tab-one",
    },
    {
      image: password_resets,
      title: "Password Reset",
      tabFor: "vertical-tab-two",
    },
    {
      image: user_provisioning,
      title: "User Provisioning",
      tabFor: "vertical-tab-three",
    },
    {
      image: access_management,
      title: "Access Management",
      tabFor: "vertical-tab-four",
    },
    {
      image: asset_requests,
      title: "Asset Requests",
      tabFor: "vertical-tab-five",
    },
    {
      image: onboarding,
      title: "Onboarding",
      tabFor: "vertical-tab-six",
    },
    {
      image: offboarding,
      title: "Offboarding",
      tabFor: "vertical-tab-seven",
    },
  ];

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = null; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const [isOpen, setOpen] = React.useState(false);

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("HR Chatbot");

  const listenScrollEvent = (e) => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  // console.log("offset", offset)

  // console.log("FeatureHomePage", offset)
  const isSmall = useMedia({ maxWidth: "520px" });
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <>
      <TitleAndMetaTags
        robots="noindex, nofollow"
        googlebot="noindex, nofollow"
        keywords={[""]}
        ogImage={topImage}
        title="IT Helpdesk Chatbot - automating employee support with Workativ"
        description="Delivering remote support for employees is more important than ever. We can help automate employee support with intelligent chatbot and process automation, out-of-the-box."
      />
      <TabProvider
        className="landing_page_chatbot"
        defaultTab={isSmall ? `section${offset + 1}` : "section1"}
        vertical
      >
        <section className="landing_it_virtual">
          <section className="container_trial integrations_container">
            <section className="header_landing_wrapper">
              <section className="header_landing header">
                <div className="desktop_menu_page">
                  <nav
                    className="navbar navbar-expand-lg fixed-top"
                    id="mainNav"
                  >
                    <div className="container">
                      <a
                        style={{ cursor: "default" }}
                        className="navbar-brand js-scroll-trigger"
                        href="javascript:viod(0)"
                      >
                        <img 
                          src={require("../assets/images/workativ-logo.png")}
                          alt="Workativ"
                        />
                      </a>
                    </div>
                  </nav>
                </div>
              </section>
            </section>
            <div className="landing-virtual-agent">
              <RegisterSection
                rightImage={topImage}
                backgroundColor={"landing_bg"}
                altImage={
                  "IT Helpdesk Chatbot can automate repetitive employee IT issues"
                }
              >
                <RegisterSection.FormWithContent>
                  <RegisterSection.MainHeader>
                    IT Helpdesk Chatbot can automate 80% of your employee IT
                    issues in seconds
                  </RegisterSection.MainHeader>
                  <RegisterSection.SubHeader>
                    Workativ helps you automate your employee  IT issues and
                    service requests with an intelligent IT helpdesk chatbot,
                    combined with IT workflow automation.
                  </RegisterSection.SubHeader>
                </RegisterSection.FormWithContent>
              </RegisterSection>
            </div>
            <section className="center_image_home" id={"customer"}>
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      {isSmall ? (
                        <h2>
                          Fortune 500 trusts us – and you can too. Give us a
                          try. Its FREE
                        </h2>
                      ) : (
                        <h2>
                          Fortune 500 trusts us – and you can too <br></br> Give
                          us a try. Its FREE
                        </h2>
                      )}
                      <picture
                        style={{ marginTop: 20, width: "100%", float: "left" }}
                      >
                        <source media="(max-width:768px)" srcSet={mobile} />
                        <img loading = "lazy"
                          src={require("../assets/landing_servicenow/ITC_logos_IT_chatbot.png")}
                          alt="Companies automating employee support with Workativ’s IT Helpdesk Chatbot"
                        ></img>
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div
              className="servicenow_virtual"
              style={{ marginBottom: "35px" }}
            >
              <section
                className="features_img_left pl-10-tage landing_page trial_page_img bg_landing_white "
                id="marketplace"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 mob_slider_pad_0">
                      <div className="center_feature_wrapper">
                        <h2>Designed to help you automate 10x faster</h2>
                      </div>
                      <div className="int_landing_page" {...handlers}>
                        <TabList className="horizontal_slider">
                          <Tab offset={slides[offset + 0]} tabFor="section1">
                            IT Helpdesk Chatbot
                          </Tab>

                          <Tab offset={slides[offset + 1]} tabFor="section2">
                            Workflow Automation
                          </Tab>

                          <Tab offset={slides[offset + 2]} tabFor="section3">
                            App Marketplace
                          </Tab>

                          <Tab offset={slides[offset + 3]} tabFor="section4">
                            Omnichannel
                          </Tab>
                          <Tab offset={slides[offset + 4]} tabFor="section5">
                            Agent Handover
                          </Tab>
                        </TabList>

                        <TabPanel offset={slides[offset + 0]} tabId="section1">
                          <Section1 />
                        </TabPanel>
                        <TabPanel offset={slides[offset + 1]} tabId="section2">
                          <Section2 />
                        </TabPanel>
                        <TabPanel offset={slides[offset + 2]} tabId="section3">
                          <Section3 />
                        </TabPanel>
                        <TabPanel offset={slides[offset + 3]} tabId="section4">
                          <Section4 />
                        </TabPanel>
                        <TabPanel offset={slides[offset + 4]} tabId="section5">
                          <Section5 />
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section className="video_section">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 flex_home">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
                    <div className="cogni_fav_apps_header">
                      <h3>Steps to build IT Helpdesk Chatbot</h3>
                    </div>
                    <div className="features_change_img">
                      <div className="features_change_img-center">
                        {!playVideo ? (
                          <img loading = "lazy"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setPlayVideo((playVideo) => !playVideo)
                            }
                            src={mock_video}
                          />
                        ) : (
                          <video
                            src="https://s3.ap-south-1.amazonaws.com/web.workativ/video/How+to+build+IT+Helpdesk+Chatbot+in+10+Mins.mp4"
                            autoplay
                            loop
                            controls
                            id="vid"
                          ></video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="servicenow_virtual">
              <AppsIntegrations />
            </div>
            <section className="center_image_home cards_landing_page">
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      <h3>Your data is yours and you control it</h3>
                      <p>
                        Workativ has implemented robust security processes and
                        controls that are in compliance with industry-leading
                        standards and regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Cards cards={cardData} />
            </section>
            <RequestForm product="WORKATIV" isFooterForm={true} />
            <Footer />
          </section>
        </section>
      </TabProvider>
    </>
  );
}

function AppsIntegrations() {
  return (
    <section className="apps_landing_page" id={"integrations"}>
      <div className="container">
        <div className="apps_landing_header_wrapper">
          <div className="apps_landing_header">
            <h3>
              Connect IT Helpdesk Chatbot with 70+ apps, 600+ actions, and 1000s
              workflow automations instantly. No coding
            </h3>
          </div>
          <div className="apps_landing_cards_section">
            {myObj.map((apps) => (
              <div className="apps_landing_cards col-lg-3 col-md-6 col-12">
                <h5>{apps.header}</h5>
                <div className="apps_landing_cards_wrapper">
                  <ul>
                    {apps.userApps.map((apps) => (
                      <li>
                        <img loading = "lazy" src={apps.icon} alt={apps.appName} />
                        <span>{apps.appName}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <p className="landing_content_p">
            Also available Document Management, Expense Management, Email
            Management, SMS Management, Notifications, and Collaboration Apps.
          </p>
        </div>
      </div>
    </section>
  );
}

const myObj = [
  {
    header: "19+ ITSM Apps",
    userApps: [
      {
        icon: servicenow,
        appName: "ServiceNow",
      },
      {
        icon: freshservice,
        appName: "Freshservice",
      },
      {
        icon: jira,
        appName: "Jira Service Desk",
      },
      {
        icon: solarwinds,
        appName: "SolarWinds",
      },
      {
        icon: service_desk,
        appName: "ServiceDesk Plus",
      },
    ],
  },
  {
    header: "15+ Access Management Apps",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
      },
    ],
  },
  {
    header: "13+ HR Apps",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR",
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM",
      },
      {
        icon: zoho_people,
        appName: "Zoho People",
      },
      {
        icon: gusto,
        appName: "Gusto",
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors",
      },
    ],
  },
  {
    header: "09+ MDM Apps",
    userApps: [
      {
        icon: intune,
        appName: "Microsoft Intune",
      },
      {
        icon: mobileiron,
        appName: "MobileIron",
      },
      {
        icon: hexnode,
        appName: "Hexnode",
      },
      {
        icon: citrix,
        appName: "Citrix",
      },
      {
        icon: service_desk,
        appName: "ManageEngine MDM",
      },
    ],
  },
];

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "unlock account",
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "password resets",
  },
  {
    tabFor1: "vertical-tab-three",
    tabName: "user provisioning",
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "access management",
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "asset requests",
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: " onboarding",
  },
  {
    tabFor1: "vertical-tab-seven",
    tabName: "offboarding",
  },
];

function Footer() {
  return (
    <section className="landing_bg">
      <div className="footer_landing">
        <div className="container">
          <div className="footer_landing_row">
            <img loading = "lazy"
              style={{ cursor: "default" }}
              src={require("../assets/images/workativ-logo.png")}
              alt="Workativ"
            />
            <div className="text-right sm-align-left">
              <p>
                <span>Workativ Copyrights © 2021. All rights reserved.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Section1() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white "
      id="section1"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img loading = "lazy" src={icon1} />
            </div>
            <h3>Cover a wide range of topics for your IT Helpdesk Chatbot </h3>
            <p>
              Using workativ’s platform, you can add, remove or optimize new
              topics for the IT helpdesk chatbot easily based on your
              requirement. Use our no-code canvas to create engaging employee
              experiences.
            </p>
            <p className="second_p">
              Go digital with employee support – faster, smarter, and easier.
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img loading = "lazy" src={section_two} alt="IT Helpdesk Chatbot" />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section2() {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slides = [
    {
      image: unlock_account,
      title: "Unlock Account",
      tabFor: "vertical-tab-one",
    },
    {
      image: password_resets,
      title: "Password Reset",
      tabFor: "vertical-tab-two",
    },
    {
      image: user_provisioning,
      title: "User Provisioning",
      tabFor: "vertical-tab-three",
    },
    {
      image: access_management,
      title: "Access Management",
      tabFor: "vertical-tab-four",
    },
    {
      image: asset_requests,
      title: "Asset Requests",
      tabFor: "vertical-tab-five",
    },
    {
      image: onboarding,
      title: "Onboarding",
      tabFor: "vertical-tab-six",
    },
    {
      image: offboarding,
      title: "Offboarding",
      tabFor: "vertical-tab-seven",
    },
  ];

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 100000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const [isOpen, setOpen] = React.useState(false);

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("HR Chatbot");

  const listenScrollEvent = (e) => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <TabProvider defaultTab="vertical-tab-one" vertical>
      <section
        className="features_img_left pl-10-tage landing_page trial_page_img bg_landing_white landing_page_chatbot ai_cards_service"
        id="section2"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 feature_page_content_left">
              <div className="iocn_virtual">
                <img loading = "lazy" src={icon2} />
              </div>
              <h2>IT Chatbot + IT Workflow Automation in a single platform</h2>
              <p>
                Workativ platform provides one-click integration of your IT
                Chatbot with your apps to streamline and automate repetitive IT
                issues & requests such as{" "}
                {buttonMenu.slice(0, 5).map((menu, i) => (
                  <li className="button_list_li">
                    <span
                      className={isActive === menu.tabName ? "" : ""}
                      onClick={() => {
                        setIsActive(menu.tabName);
                        setOffset(i);
                      }}
                    >
                      {" "}
                      {menu.content && (
                        <span className="pad_content">{menu.content}</span>
                      )}
                      {menu.tabFor1 ? (
                        <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                      ) : null}
                    </span>
                    <span className="space_left"> , </span>
                  </li>
                ))}
                and employee processes like{" "}
                {buttonMenu.slice(5, 7).map((menu, i) => (
                  <li className="button_list_li">
                    <span
                      className={isActive === menu.tabName ? "" : ""}
                      onClick={() => {
                        setIsActive(menu.tabName);
                        setIsActive(i + 5);
                      }}
                    >
                      {" "}
                      {menu.content && (
                        <span className="pad_content">{menu.content}</span>
                      )}
                      {menu.tabFor1 ? (
                        <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                      ) : null}
                    </span>
                    <span className="space_left last_child"> , </span>
                  </li>
                ))}{" "}
                and much more.
              </p>

              <p className="second_p">
                Expect a big drop in calls and emails to your IT Helpdesk. 
              </p>
              <div className="card_link_landing" />
            </div>
            <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
              <div className="desktop_tabs">
                <TabList>
                  {slides.map((menu) => (
                    <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
                  ))}
                </TabList>
                {slides.map((menu) => (
                  <TabPanel tabId={menu.tabFor}>
                    <img loading = "lazy" src={menu.image} alt={menu.title}></img>
                  </TabPanel>
                ))}
              </div>
              <div className="slider_wrapper">
                <div className="slider_container" {...handlers}>
                  <img loading = "lazy" src={slides[offset].image} alt="onboarding" />
                </div>
                <div className="controls_wrapper">
                  <span
                    className="controls_left"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      decrementOffset();
                    }}
                  >
                    <svg
                      x="0px"
                      y="0px"
                      viewBox="0 0 24 24"
                      xmlSpace="preserve"
                    >
                      <circle cx="12" cy="12" r="12" fill="#2c7357"></circle>
                      <path
                        d="M.5 12.2c.1 0 .3 0 .3-.1L7 6.5c.2-.2.2-.5 0-.7L.9.1C.6-.1.3 0 .1.2 0 .4 0 .7.2.9l5.7 5.2-5.7 5.2c-.2.2-.2.5 0 .7 0 .2.2.2.3.2z"
                        fill="#adf0d5"
                        transform="translate(8.861 6)"
                      ></path>
                    </svg>
                  </span>
                  <span>{slides[offset].title}</span>
                  <span
                    className="controls_right"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      incrementOffset();
                    }}
                  >
                    <svg
                      x="0px"
                      y="0px"
                      viewBox="0 0 24 24"
                      xmlSpace="preserve"
                    >
                      <circle cx="12" cy="12" r="12" fill="#2c7357"></circle>
                      <path
                        d="M.5 12.2c.1 0 .3 0 .3-.1L7 6.5c.2-.2.2-.5 0-.7L.9.1C.6-.1.3 0 .1.2 0 .4 0 .7.2.9l5.7 5.2-5.7 5.2c-.2.2-.2.5 0 .7 0 .2.2.2.3.2z"
                        fill="#adf0d5"
                        transform="translate(8.861 6)"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabProvider>
  );
}
function Section3() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white"
      id="section3"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img loading = "lazy" src={icon3} />
            </div>
            <h3>
              Add pre-built IT workflows to your IT Chatbot from our workflow
              Marketplace
            </h3>
            <p>
              Workativ comes with over 600+ pre-built workflow automations for
              popular apps like ITSM, HR, Access Management, MDM, Knowledge
              Management and so on, that are ready to use from our workflow
              marketplace.  
            </p>
            <p>No coding. Just download, connect, and go live instantly. </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img loading = "lazy"
              src={section_four}
              alt="Deploy IT Helpdesk Chatbot on your Slack or Teams in few clicks."
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section4() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white"
      id="section4"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img loading = "lazy" src={icon4} />
            </div>
            <h3>
              Deploy IT Helpdesk Chatbot on your Slack, Teams, or as a Chat
              Widget in few clicks
            </h3>
            <p>
              Workplace support automation using IT Chatbot has shown to improve
              MTTR and FCR. Customers have seen an average of 98% CSAT in the
              employee service experience. 
            </p>
            <p>
              Delight your employees with always-on 24/7, follow-me,
              auto-resolution bot on Slack, Teams, or as Chat Widget on
              self-help portal in few clicks.
            </p>
            <SlackAndTeams />
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img loading = "lazy"
              src={section_five}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section5() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white"
      id="section5"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img loading = "lazy" src={icon5} />
            </div>
            <h3>Live Agent Handover</h3>
            <p>
              Workativ offers seamless <b>‘agent handover’</b> of IT Helpdesk
              Chatbot to live agents with complete context and conversation
              history so your agents can pick up right where the bot left,
              avoiding any user frustration – but most importantly solving
              end-user issues right away.
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img loading = "lazy"
              src={section_six}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>
        </div>
      </div>
    </section>
  );
}
